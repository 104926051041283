import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><a parentName="h4" {...{
        "href": "/"
      }}>{`home`}</a>{` / `}<a parentName="h4" {...{
        "href": "/resume"
      }}>{`resume`}</a>{` / `}<a parentName="h4" {...{
        "href": "/projects"
      }}>{`projects`}</a></h4>
    <hr></hr>
    <h1>{`>_ 404 page not found`}</h1>
    <p><em parentName="p">{`Sorry we couldn't find what you were looking for. `}<a parentName="em" {...{
          "href": "/"
        }}>{`Go home`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      